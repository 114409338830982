import React from 'react'
import { withIntl } from 'i18n'

import Layout from '../components/Layout'
import SEO from '../components/Layout/SEO'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <h1>NOT FOUND</h1>
  </Layout>
)

export default withIntl(NotFoundPage)
